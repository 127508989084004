import React from "react"
import { FaEnvelope } from "react-icons/fa"
import Title from "./Title"

export default () => (
  <>
    <Title headroom>Contact Us</Title>
    <a href="mailto:thewigout@thewigout.life">
      <FaEnvelope /> <span>thewigout@thewigout.life</span> <FaEnvelope />
    </a>
  </>
)
