import styled from "styled-components"

const Title = styled.div<{
  color?: string
  shadowColor?: string
  headroom?: boolean
  mega?: boolean
}>`
  margin: ${props => (props.headroom ? "60px" : "20px")} auto 20px auto;
  flex: 0 1;
  ${props => (props.color ? `color: ${props.color};` : "")}
  text-shadow: ${props =>
    props.shadowColor ? `1px 1px ${props.shadowColor}` : "none"};

  font-size: ${props => (props.mega ? 42 : 30)}px;
  @media(min-width: 768px) {
    font-size: ${props => (props.mega ? 75 : 50)}px;
  }
  font-family: 'Bowlby One SC';
  text-align: center;
`
export default Title
