import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import ContactUs from "./ContactUs"

const GlobalStyle = createGlobalStyle<{ backgroundColor: string }>`
  body {
    margin: 0;
  }
`

const Wrapper = styled.div<{ textColor?: string; backgroundColor: string }>`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  padding: 10px 10px 50px 10px;
  @media (min-width: 768px) {
    padding: 50px 10px;
    p {
      max-width: 600px;
    }
  }
  ${props => (props.textColor ? `color: ${props.textColor};` : "")}
  a {
    ${props => (props.textColor ? `color: ${props.textColor};` : "")}
  }
`

const Layout: React.FC<{
  backgroundColor: string
  textColor?: string
}> = ({ backgroundColor, children, textColor }) => (
  <Wrapper textColor={textColor} backgroundColor={backgroundColor}>
    <GlobalStyle backgroundColor={backgroundColor} />
    {children}
    <ContactUs />
  </Wrapper>
)

export default Layout
